<style>
.current_resource {
  -webkit-animation: twinkling 1s infinite 0.3s ease-in-out alternate;
  animation: twinkling 1s infinite 0.3s ease-in-out alternate;
  width: 60px;
  height: 15px;
  background-color: #F54236;
  float: left;
  margin-right: 5px;
  cursor: auto;
}
.download-button{
  float: left;
  margin-right: 20px;
  margin-top: -2px;
}
@-webkit-keyframes twinkling {
  /*透明度由0到1*/
  0% {
    opacity: 0.3; /*透明度为0*/
  }
  100% {
    opacity: 1; /*透明度为1*/
  }
}
@keyframes twinkling {
  /*透明度由0到1*/
  0% {
    opacity: 0.3; /*透明度为0*/
  }
  100% {
    opacity: 1; /*透明度为1*/
  }
}
.download-svg-css{
  width: 1280px;
  height: 768px;
  z-index: -1;
  position: absolute;
  top: 50px;
}
</style>

<template>
  <div>
    <div v-if="multiShow">
      <Tabs v-model="defaultTab" @on-click="changeTabs" class="text-backgroud">
        <TabPane v-for="(item,index) in themeSvgList" :key="index" :name="'tab'+index" :label="getTabName(item)">
          <div class="right m-r-10">
            <a class="download-button" @click="handleDownloadMap(getTabName(item), 'svg-resource-theme_'+ index)">
              <Icon size="18" type="md-cloud-download" class="m-r-5"/>
              下载当前站内地图
            </a>
            <!-- 此处是一个颜色标记 -->
            <a href="javascript:void(0)" class="current_resource"></a>
            <div class="text-black inline">当前资源</div>
            <div style="clear:both"></div>
          </div>
          <div v-if="item.svg" v-html="item.svg" class="svg-station-content" style="height:500px;" :id="'svg-resource-theme_'+ index"></div>
          <div v-else class="default-content p-b-50">
            <p style="font-size: xx-large;">暂无SVG站点地图</p>
          </div>
        </TabPane>
      </Tabs>
    </div>
    <div v-else>
      <!-- <div v-show="labelShow ">
        <div>
          <a href="javascript:void(0) " class="current_resource "></a>
          <div>当前资源</div>
          <div style="clear:both "></div>
        </div>
      </div> -->
      <div v-show="labelShow " class="right m-r-10 m-t-10">
        <a class="download-button" @click="handleDownloadMap(svgInfo.stationName, 'svg-station-content')">
          <Icon size="18" type="md-cloud-download" class="m-r-5"/>
          下载当前站内地图
        </a>
        <!-- 此处是一个颜色标记 -->
        <a href="javascript:void(0)" class="current_resource"></a>
        <div class="text-black inline">当前资源</div>
        <div style="clear:both"></div>
      </div>
      <div v-if="svgInfo.svg" v-html="svgInfo.svg" class="svg-station-content " style="height:550px;" id="svg-station-content"></div>
      <div v-else class="default-content p-b-50">
        <p style="font-size: xx-large;">暂无SVG站点地图</p>
      </div>
    </div>

    <!-- 隐藏图层，用于下载站点地图时，克隆SVG代码 -->
    <div id="download-resource-svg" class="download-svg-css"></div>
    <Spin size="large" fix v-if="spinShow">
      <Icon type="ios-loading" size=18 class="-spin-icon-load"></Icon>
      <div>地图加载中</div>
    </Spin>
  </div>
</template>

<script>
import svgPanZoom from 'svg-pan-zoom'

import { HTMLEncode } from '@/utils'
import { downloadFilePostRequest } from '@/utils/download'
import { getSkuSvgList } from '@/api/product/stationgraph'

export default {
  name: 'SvgResourceComponent',
  data () {
    return ({
      svgInfo: {
        svg: '',
        floor: null,
        stationId: 0,
        stationName: '',
        positionName: '',
        position: null
      },
      multiShow: false,
      labelShow: false,
      defaultTab: 'tab0',
      themeSvgList: [],
      existSvgZoom: [],
      deviceList: [],

      spinShow: false
    })
  },
  methods: {
    bindLightBoxData (data, deviceId) {
      if (!data.svg || data.svg.length < 1) { return }

      this.multiShow = false
      this.labelShow = true
      this.svgInfo = data
      this.$nextTick(() => {
        const cuurentSvgElement = document.getElementById('svg-station-content').firstElementChild
        // 设置svg呈现在画板上的尺寸
        cuurentSvgElement.setAttribute('width', '100%')
        cuurentSvgElement.setAttribute('height', '100%')
        var curMap = svgPanZoom(cuurentSvgElement, {
          panEnabled: true,
          controlIconsEnabled: false,
          zoomEnabled: true,
          dblClickZoomEnabled: true,
          center: true
        }).zoom(1.3)

        if (cuurentSvgElement.id === 'drawing') {
          // 旧版本使用path获取点位节点
          const pathNodeList = cuurentSvgElement.getElementsByTagName('path')
          if (pathNodeList.length > 0) {
            for (let i = 0; i < pathNodeList.length; i++) {
              const el = pathNodeList[i]
              if (el.hasAttribute('data-id')) {
                const rId = parseInt(el.getAttribute('data-id'))
                if (rId === deviceId) {
                  el.setAttribute('fill', '#F54236')
                  el.setAttribute('style', ' -webkit-animation: twinkling 1s infinite 0.5s ease-in-out alternate;animation: twinkling 1s infinite 0.5s ease-in-out alternate')
                }
              }
            }
          }
        } else {
          // 新版本使用g获取点位节点
          curMap.zoom(2)
          // 移除title标签， 改标签用于tips
          if (cuurentSvgElement.lastElementChild.lastElementChild.firstElementChild.nodeName === 'title') {
            cuurentSvgElement.lastElementChild.lastElementChild.removeChild(
              cuurentSvgElement.lastElementChild.lastElementChild.firstElementChild
            )
          }

          const gNodeList = cuurentSvgElement.getElementsByTagName('g')
          if (gNodeList.length > 0) {
            for (let i = 0; i < gNodeList.length; i++) {
              const el = gNodeList[i]
              if (el.hasAttribute('data-id')) {
                const rId = parseInt(el.getAttribute('data-id'))
                if (rId === deviceId) {
                  el.setAttribute('fill', '#F54236')
                  for (let h = 0; h < el.children.length; h++) {
                    el.children[h].setAttribute('fill', '#F54236')
                    el.children[h].setAttribute('style', ' -webkit-animation: twinkling 1s infinite 0.3s ease-in-out alternate;animation: twinkling 1s infinite 0.3s ease-in-out alternate')
                  }
                }

                // 添加显示媒体编号
                this.addMediaCode(el)
              }
            }
          }
        }
      })
    },
    bindTrainData (data) {
      if (!data.svg || data.svg.length < 1) { return }

      this.labelShow = false
      this.svgInfo = data
      this.$nextTick(() => {
        const cuurentSvgElement = document.getElementById('svg-station-content').firstElementChild
        // 设置svg呈现在画板上的尺寸
        cuurentSvgElement.setAttribute('width', '100%')
        cuurentSvgElement.setAttribute('height', '100%')
        svgPanZoom(cuurentSvgElement, {
          panEnabled: true,
          controlIconsEnabled: false,
          zoomEnabled: true,
          dblClickZoomEnabled: true,
          center: true
        }).zoom(1.3)
      })
    },
    bindThemeData (productskuId, tagResoursArray) {
      this.deviceList = tagResoursArray
      this.existSvgZoom = []
      this.themeSvgList = []
      this.defaultTab = 'tab0'
      this.multiShow = true
      this.labelShow = true

      getSkuSvgList({ skuId: productskuId }).then(res => {
        this.themeSvgList = res
        this.changeTabs(this.defaultTab)
      })
    },
    changeTabs (name) {
      this.defaultTab = name
      const currentSvgId = 'svg-resource-theme_' + name.substr(3)
      this.beginSvgPanZoom(currentSvgId)
    },
    beginSvgPanZoom (svgId) {
      if (this.existSvgZoom.indexOf(svgId) > -1) {
        return
      } else {
        this.existSvgZoom.push(svgId)
      }

      this.$nextTick(() => {
        const currentSvgElement = document.getElementById(svgId).firstElementChild
        // 设置svg呈现在画板上的尺寸
        currentSvgElement.setAttribute('width', '100%')
        currentSvgElement.setAttribute('height', '100%')

        var curMap = svgPanZoom(currentSvgElement, {
          panEnabled: true,
          controlIconsEnabled: false,
          zoomEnabled: true,
          dblClickZoomEnabled: true,
          center: true
        }).zoom(1.3)

        if (currentSvgElement.id === '') { // 新版地图
          curMap.zoom(1.5)
          // 移除title标签， 改标签用于tips
          if (currentSvgElement.lastElementChild.lastElementChild.firstElementChild.nodeName === 'title') {
            currentSvgElement.lastElementChild.lastElementChild.removeChild(
              currentSvgElement.lastElementChild.lastElementChild.firstElementChild
            )
          }

          const gNodeList = currentSvgElement.getElementsByTagName('g')
          if (gNodeList.length > 0 && this.deviceList.length > 0) {
            for (let i = 0; i < gNodeList.length; i++) {
              const el = gNodeList[i]
              if (el.hasAttribute('data-id')) {
                const rId = parseInt(el.getAttribute('data-id'))

                if (this.deviceList.indexOf(rId) > -1) {
                  for (let h = 0; h < el.children.length; h++) {
                    // 单独处理异性墙贴
                    if (el.getAttribute('data-devicemodelid') === '110') {
                      if (el.children[h].nodeName === 'g') { // 多个元素组，合并组合而成的墙贴
                        for (let gIndex = 0; gIndex < el.children[h].children.length; gIndex++) {
                          el.children[h].children[gIndex].setAttribute('stroke-width', '1')
                          el.children[h].children[gIndex].setAttribute('stroke', '#F54236')
                          el.children[h].children[gIndex].setAttribute('style', ' -webkit-animation: twinkling 1s infinite 0.3s ease-in-out alternate;animation: twinkling 1s infinite 0.3s ease-in-out alternate')
                        }
                      } else {
                        el.children[h].setAttribute('stroke', '#F54236')
                        el.children[h].setAttribute('stroke-width', '1')
                        el.children[h].setAttribute('style', ' -webkit-animation: twinkling 1s infinite 0.3s ease-in-out alternate;animation: twinkling 1s infinite 0.3s ease-in-out alternate')
                      }
                    } else {
                      el.children[h].setAttribute('fill', '#F54236')
                      el.children[h].setAttribute('style', ' -webkit-animation: twinkling 1s infinite 0.3s ease-in-out alternate;animation: twinkling 1s infinite 0.3s ease-in-out alternate')
                    }
                  }
                }

                // 添加显示媒体编号
                this.addMediaCode(el)
              }
            }
          }
        } else {
          const pathNodeList = document.getElementsByTagName('path')
          if (pathNodeList.length > 0) {
            for (let i = 0; i < pathNodeList.length; i++) {
              const el = pathNodeList[i]
              if (el.hasAttribute('data-id')) {
                const rId = parseInt(el.getAttribute('data-id'))
                if (this.deviceList.indexOf(rId) > -1) {
                  el.setAttribute('fill', '#F54236')
                  el.setAttribute('style', ' -webkit-animation: twinkling 1s infinite 0.3s ease-in-out alternate;animation: twinkling 1s infinite 0.3s ease-in-out alternate')
                }
              }
            }
          }
        }
      })
    },
    addMediaCode (curElement) {
      const deviceCode = curElement.getAttribute('data-devicecode')
      const deviceModelId = curElement.getAttribute('data-devicemodelid')

      // 移除括号内的值
      let formatDeviceCode = deviceCode
      if (deviceCode.includes('(') && deviceCode.includes(')')) {
        formatDeviceCode = deviceCode.split('(')[0] + deviceCode.split(')')[1]
      }
      const subDeviceCode = formatDeviceCode.split('-')
      let realLable = subDeviceCode[subDeviceCode.length - 1] // 真实显示出来的编号
      let startX = 9

      if (deviceModelId === '143') { // 单边梯牌需要单独处理， 例：BS03-19
        realLable = subDeviceCode[subDeviceCode.length - 2] + '-' + subDeviceCode[subDeviceCode.length - 1]
        startX = 10
      } else if (deviceModelId === '106') { // 梯牌， 只显示数字，例：06
        realLable = realLable.substr(2)
        startX = 2
      } else if (deviceModelId === '114') {
        startX = 3
      }

      // 开始显示设备编号
      let elementBBox = null
      let elementX = null
      let elementY = null
      if (curElement.lastElementChild.getAttribute('type') !== 'deviceCode') { // 显示出设备CODE
        // 判断是否已经自动添加了编号， 如果有。 那么不再添加
        for (let cindex = 0; cindex < curElement.children.length; cindex++) {
          if (curElement.children[cindex].nodeName === 'text' && curElement.children[cindex].textContent === realLable) {
            // 退出循环
            return
          }
        }

        // 如果当前元素执行过svg的transform-matrix
        const style = window.getComputedStyle(curElement)
        elementBBox = curElement.getBBox()
        // 可能是宽高被颠倒了
        if (elementBBox.x < 0 || elementBBox.y < 0) {
          elementX = elementBBox.width
          elementY = elementBBox.height
        } else {
          elementX = elementBBox.x
          elementY = elementBBox.y
        }
        // 创建一个text，并添加到path后面
        const text = document.createElementNS('http://www.w3.org/2000/svg', 'text')

        if (style.transform && style.transform.includes('matrix')) {
          const matrixValues = style.transform.match(/matrix.*\((.+)\)/)[1].split(', ')
          const a = matrixValues[0]
          const b = matrixValues[1]
          const scale = Math.sqrt(a * a + b * b)

          text.setAttribute('font-size', 5 / scale)
          text.setAttribute('x', elementX + startX)
        } else {
          text.setAttribute('font-size', 5)
          text.setAttribute('x', elementX + startX + 2)
        }

        text.setAttribute('fill', '#000')
        text.setAttribute('y', elementY - 2)
        text.setAttribute('type', 'deviceCode')
        // text.setAttribute('font-weight', 'bold')
        text.setAttribute('font-style', 'normal')
        text.setAttribute('text-anchor', 'middle')
        text.setAttribute('text-decoration', 'blink')
        text.setAttribute('font-family', '微软雅黑,黑体,Arial,宋体')

        text.textContent = realLable
        curElement.appendChild(text)
      }
    },
    getTabName (item) {
      return item.stationName + ' ' + ((item.floor > 0) ? '地上' + item.floor + '层' : '地下' + item.floor + '层')
    },
    handleDownloadMap (stationName, downloadSvgId) {
      this.spinShow = true
      const fileName = stationName + '.png'

      // 设置需要下载的原图
      const downloadElement = document.getElementById('download-resource-svg')
      downloadElement.innerHTML = document.getElementById(downloadSvgId).innerHTML
      this.$nextTick(() => {
        const currMap = svgPanZoom('#download-resource-svg svg')
        currMap.zoomAtPointBy(4, { x: -150, y: -200 })
        // 添加档期和备注信息
        // this.addMapScheduleHelper(downloadElement.firstElementChild.lastElementChild)
        // 开始往地图中添加图例标识
        // this.addMapIcon(downloadElement.firstElementChild.lastElementChild)

        const that = this
        setTimeout(function () {
          that.spinShow = false
          const postData = {
            svg: HTMLEncode(downloadElement.innerHTML)
          }
          downloadFilePostRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-draw/svg/downloadsvgpng', postData, fileName)
          downloadElement.innerHTML = ''
        }, 1000)
      })
    }
  }
}
</script>
